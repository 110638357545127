<template>
    <div class="certificate-item">
        <div class="select-top" :class="role === '2' ? 'no-margin' : ''">
            <div class="class-screening">
                <span class="class-screening-title">班级名称:</span>
                <el-select v-model="classValue" placeholder="请选择" @change="classSelectBtn($event)">
                    <el-option
                            v-for="item in optionsSelect"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="select-right">
                <span class="class-screening-title">考试时间:</span>
                <el-select style="width:110px;margin-right: 10px;" v-model="pickerType" @change="pickerTypeChange">
                    <el-option v-for="item in options" :key="item.key" :label="item.value" :value="item.key">
                    </el-option>
                </el-select>
                <el-date-picker style="width:150px" v-model="time1" :type="datePickerType" format="yyyy-MM-dd"
                                placeholder="选择日期" @change="time1Change">
                </el-date-picker>
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                <el-date-picker style="width:150px" v-model="time2" readonly>
                </el-date-picker>
            </div>
        </div>

        <el-table :data="certificateTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column prop="name" label="考试名称" width="500px"></el-table-column>
            <el-table-column prop="class_name" label="班级名称"></el-table-column>
            <el-table-column label="考试人数" align="center">
                <template slot-scope="scope">
                    <span class="people-title">{{scope.row.exam_student_num}}人</span>
                </template>
            </el-table-column>
            <el-table-column prop="get_rate" label="获证率" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="primary" :underline="false" @click="getCertificateList(scope.row)">已获得证书({{scope.row.get_certificate_student}})</el-link>
                        <el-link type="success" :underline="false" @click="getNoCertificateList(scope.row)">未获得证书({{scope.row.noget_certificate_student}})</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="certificatePages.currentPageNum"
                :page-size="certificatePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="certificatePages.total"
                @current-change="certificateCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {certificateGetExamList,getClassName} from '@/utils/apis'
    export default {
        name: "Index",
        data(){
            return{
                role:'',//角色
                classValue:0,
                optionsSelect:[],
                // exam_time: null, //时间筛选变量
                time1: "",
                time2: "",
                datePickerType: "date",
                dataFormat: "yyyy-MM-dd",
                pickerType: "按天查询",
                options: [
                    {
                        key: "date",
                        value: "按天查询",
                    },
                    {
                        key: "week",
                        value: "按周查询",
                    },
                    {
                        key: "month",
                        value: "按月查询",
                    },
                ],
                certificateTable:[],
                //分页
                certificatePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.role = localStorage.getItem('role');
        },
        mounted() {
            this.getCertificateListList()
            this.getClassList()
        },
        methods:{
            //时间筛选
            pickerTypeChange(e) {
                this.datePickerType = e;
                this.time1 = "";
                this.time2 = "";
            },
            time1Change(e) {
                this.certificatePages.currentPageNum = 1
                if(!e){
                    this.time1 = ''
                    this.time2 = ''
                    this.getCertificateListList()
                    return
                }
                if (this.datePickerType === "date") {
                    this.time2 = new Date(e.getTime() + 24 * 60 * 60 * 1000);
                } else if (this.datePickerType === "week") {
                    this.time1 = new Date(e.getTime() - 24 * 60 * 60 * 1000);
                    this.time2 = new Date(e.getTime() + 24 * 60 * 60 * 1000 * 5);
                } else {
                    this.time1 = e;
                    let year = e.getFullYear();
                    let month = e.getMonth() + 1;
                    let d = new Date(year, month, 0);
                    this.time2 = new Date(
                        e.getTime() + 24 * 60 * 60 * 1000 * (d.getDate())
                    );
                }
                this.getCertificateListList()
            },
            // 获取证书列表
            getCertificateListList(){
                let params = {
                    paging:1,
                    pageSize:this.certificatePages.eachPageNum,
                    page:this.certificatePages.currentPageNum
                }
                if(this.classValue){
                    params.class_id = this.classValue
                }
                if (this.time1) {
                    params.begin_time = this.time1.getTime() / 1000;
                    params.end_time = this.time2.getTime() / 1000;
                }
                certificateGetExamList(params).then((res) => {
                    this.certificateTable = res.data.data
                    this.certificatePages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //班级筛选
            classSelectBtn(e){
                this.classValue = e;
                this.certificatePages.currentPageNum = 1
                this.getCertificateListList()
            },
            //获取班级列表
            getClassList(){
                getClassName().then((res)=>{
                    this.optionsSelect = res.data.list;
                    let ob = {
                        id: 0,
                        name: "全部"
                    };
                    this.optionsSelect.unshift(ob);
                }).catch((err)=>{
                    console.log('err', err)
                })
            },
            // 已获得证书列表
            getCertificateList(row){
                if(this.role === '2'){
                    this.$router.push({
                        path:'/school/certificate/getCertificateList',
                        query:{
                            exam_id:row.id,
                            exam_name:row.name
                        }
                    })
                }else {
                    this.$router.push({
                        path:'/evaluationCenter/getCertificateList',
                        query:{
                            exam_id:row.id,
                            exam_name:row.name
                        }
                    })
                }
            },
            //未获得证书列表
            getNoCertificateList(row){
                if(this.role === '2'){
                    this.$router.push({
                        path:'/school/certificate/noGetCertificateList',
                        query:{
                            exam_id:row.id,
                            // exam_name:row.name,
                            // no_student_id:row.no_student_id
                        }
                    })
                }else {
                    this.$router.push({
                        path:'/evaluationCenter/noGetCertificateList',
                        query:{
                            exam_id:row.id,
                            // exam_name:row.name,
                            // no_student_id:row.no_student_id
                        }
                    })
                }
            },
            // 切换分页
            certificateCurrentChange(val){
                this.certificatePages.currentPageNum = val;
                this.getCertificateListList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .certificate-item{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .select-top{
            display: flex;
            align-items: center;
            margin: 20px 0;
            .select-right{
                margin-left: 60px;
            }

            .class-screening-title{
                margin-right: 10px;
            }
        }
        .no-margin {
            margin: 0 0 20px 0 !important;
        }
    }

</style>